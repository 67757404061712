import React, { useState, useEffect } from "react"
import { Link } from 'gatsby'
import "../scss/NavigationBar.scss"
import SearchBox from './searchBox/SearchBox';

function NavigationBar(props) {
    const { siteNav } = props;

    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        window.addEventListener("scroll", () => {
         setScroll(window.scrollY > 25);
        });
    }, []);

    let menu = siteNav.menu.map((item, i) => { // loops through menu items

        if (item.children && item.children.length > 0) { // if a drop down go down a deeper level and build drop down code
            let subMenu = item.children.map((subItem, x) => {
                if (subItem.children && subItem.children.length > 0) { // goes to the lowest level to check for drop downs within drop downs

                    let lowestMenuLevel = subItem.children.map((lowItem) => {
                        return lowItem.link.linkPath.length > 0 ? (
                            <Link id='nav_link' key={lowItem.link.linkName} className='nav-smallDrop' to={lowItem.link.linkPath}>
                                <span>{lowItem.link.linkName}</span>
                            </Link>
                        ) : (
                                <p key={lowItem.link.linkName} className='nav-smallDrop'>
                                    <span>{lowItem.link.linkName}</span>
                                </p>
                            )
                    })

                    return (
                        <li className="nav-li" key={x}>
                            <div className="button button_color-white button_font-small menu__button js-button">
                                <span className="button__subtitle js-button-subtitle dropHover">{subItem.link.linkName}</span>
                            </div>
                            <div className="nav-sub">
                                {lowestMenuLevel}

                            </div>
                        </li>
                    )
                } else {
                    return (
                        <li key={subItem.link.linkName}>
                            <Link id='nav_link' className="button button_color-white button_font-small menu__button js-button dropHover" to={subItem.link.linkPath}>
                                <span className="button__subtitle js-button-subtitle">{subItem.link.linkName}
                                </span>
                            </Link>
                        </li>
                    )
                }
            })

            return item.link.linkPath.length > 0 ? (
                <div className="header__item js-header-item dropdown dropdown-6">
                    <Link id='nav_link' key={i} to={item.link.linkPath} className="">
                        <span className="button button_color-default button_font-small menu__button">
                            <span className="button__subtitle js-button-subtitle">{item.link.linkName} <span className="header-arrow"></span>
                            </span>
                        </span>
                    </Link>
                    <ul id={item.link.linkName} className="dropdown_menu dropdown_menu--animated dropdown_menu-6">
                        {subMenu}
                    </ul>
                </div>
            ) : (
                    <p key={i} className="header__item js-header-item dropdown dropdown-6">
                        <span className="button button_color-default button_font-small menu__button">
                            <span className="button__subtitle js-button-subtitle">{item.link.linkName}
                            </span>
                        </span>
                        <ul id={item.link.linkName} className="dropdown_menu dropdown_menu--animated dropdown_menu-6">
                            {subMenu}
                        </ul>
                    </p>
                )
        } else {
            return item.link.linkPath.length > 0 ? (
                <Link id='nav_link' to={item.link.linkPath} key={item.link.linkName} className="header__item js-header-item " >
                    <span className="button button_color-default button_font-small menu__button js-button">
                        <span className="button__subtitle js-button-subtitle">{item.link.linkName}
                        </span>
                    </span>
                </Link>
            ) : (
                <p key={item.link.linkName} className="header__item js-header-item " >
                    <span className="button button_color-default button_font-small menu__button js-button">
                        <span className="button__subtitle js-button-subtitle">{item.link.linkName} </span>
                    </span>
                </p>
            )
        }
    })

    let mobileMenu = siteNav.menu.map((item, i) => {
        if (item.children && item.children.length > 0) { // if a drop down go down a deeper level and build drop down code

            let subMenu = item.children.map((subItem, x) => {
                if (subItem.children && subItem.children.length > 0) {
                    let lowestMenuLevel = subItem.children.map((lowItem) => {
                        return lowItem.link.linkPath.length > 0 ?
                            (
                                <Link id='nav_link' key={lowItem.link.linkName} className="nav-link nav-drop" to={lowItem.link.linkPath}>

                                    <span className="nav-span nav-small">{lowItem.link.linkName}</span>
                                </Link>
                            ) : (
                                <p key={lowItem.link.linkName} className="nav-link nav-drop">
                                    <span className="nav-span nav-small">{lowItem.link.linkName}</span>
                                </p>
                            )
                    })

                    return (
                        <div key={x} className="nav-link nav-drop nav-secondDrop">
                            <span onClick={() => toggleDrop(subItem.link.linkName + '-mobile')} className="nav-span">{subItem.link.linkName} <span className="header-arrow"></span>
                            </span>
                            <div className="nav-dropInfo" id={subItem.link.linkName + '-mobile'}>
                                {lowestMenuLevel}
                            </div>
                        </div>

                    )
                } else {
                    return subItem.link.linkPath.length > 0 ?
                        (
                            <Link id='nav_link' key={subItem.link.linkName} className="nav-link nav-drop" to={subItem.link.linkPath} onClick={()=> toggleMobile()}>
                                <span className="nav-span nav-small">{subItem.link.linkName}</span>
                            </Link>
                        ) : (
                            <p key={subItem.link.linkName} className="nav-link nav-drop">
                                <span className="nav-span nav-small">{subItem.link.linkName}</span>
                            </p>
                        )
                }
            })

            return (
                <div key={i} className="nav-link">
                    <span onClick={() => toggleDrop(item.link.linkName + '-mobile')} className="nav-span">{item.link.linkName}  <span className="header-arrow"></span>
                    </span>
                    <div className="nav-dropInfo" id={item.link.linkName + '-mobile'}>
                        {subMenu}
                    </div>
                </div>
            )
        } else {
            return item.link.linkPath.length > 0 ? (
                <Link id='nav_link' key={item.link.linkName} className="nav-link" to={item.link.linkPath} onClick={() => toggleMobile()}>
                    <span className="nav-span">{item.link.linkName}</span>
                </Link>
            ) : (
                    <p key={item.link.linkName} className="nav-link" onClick={() => toggleMobile()}>
                        <span className="nav-span">{item.link.linkName}</span>
                    </p>
                )
        }
    })

    return (
        <header id="header">
            <div className={scroll ? "header site__section js-header header_opacity" : "header site__section js-header"} /*style={{ top: "38px" }}*/>
                <section className="header__section site__subsection">
                    <Link id='nav_link' className="button button_type-icon header__logo icon_logo js-button nav-logo" to="/">
                    <img className="nav-icon" src={props.dealerInfo.dealerLogo} alt={props.dealerInfo.dealerName} />
                    <div className="MXSLogoBottom">
          MODERN/
          <div className="MXSLogoSlider">
              <div className="logoSlider-text0">MARKETING</div>
              <div className="logoSlider-text1">DIGITAL</div>
              <div className="logoSlider-text2">WEBSITE</div>
              <div className="logoSlider-text3">AUTOMOTIVE</div>
              <div className="logoSlider-text4">DEVELOPMENT</div>
              <div className="logoSlider-text5">SOFTWARE</div>
              <div className="logoSlider-text6">eCOMMERCE</div>
              <div className="logoSlider-text7">BRANDING</div>
              <div className="logoSlider-text8">CREATIVE</div>
              <div className="logoSlider-text9">MARKETING</div>
          </div>
          /SOLUTIONS
        </div>
                    </Link>
                    { //Commented out beccause it throws off the template
                    //<Link id='nav_link' className="button button_type-icon header__logo js-button text_logo" to="/">
                    //   <span>{props.dealerInfo.dealerName.substring(0, props.dealerInfo.dealerName.indexOf(" "))}</span>
                    //   {props.dealerInfo.dealerName.substring(props.dealerInfo.dealerName.indexOf(" "), props.dealerInfo.dealerName.length)}
                    //</Link>
                    }
                </section>
                <section className="header__section site__subsection">
                    <menu className="menu js-menu">
                        <div className="menu__wrapper">
                            {menu}
                        </div>
                    </menu>
                </section>
                <a onClick={() => toggleMobile()} className="burger header__explore-menu js-header-explore-button">
                    <span className="collapsible-menu" alt="hamburger icon for expandable menu"/>
                    <span class="menu-label">Menu</span>
                </a>

                <Link id='nav_link' className="header_cta" to={"?title=Get%20In%20Touch%20With%20Us#contact-form"}>
                    Learn More
                </Link>

            </div>
            <section className='nav-mobile'>
                <div id="mobileDropDown" className="nav-cont">
                    <div className="nav-overflow">
                        {mobileMenu}
                    </div>
                </div>
            </section>
        </header>
    )
}

function toggleDrop(id) {
    const div = document.getElementById(id)
    div.classList.toggle('showDrop')
}

function toggleMobile() {
    if(document.body.classList.contains("scroll-hide")){
        document.body.classList.remove("scroll-hide")
    } else {
        document.body.classList.add("scroll-hide")
    }

    document.getElementById("mobileDropDown").classList.toggle('showMobile');
    document.getElementsByClassName("header__explore-menu")[0].classList.toggle('state_toggled');
}

export default NavigationBar

