import React, { useState, useCallback, useRef } from "react"
import { Link } from "gatsby"
import { captureEvent } from 'event-service/modules/capture'

import grid from '../../scss/flexboxgrid.module.scss'
import view from './footer.module.scss'
import CookiePolicy from "../cookie/CookiePolicy"

function Footer(props) {
  const { siteNav, dealerInfo, footer, footerClassName } = props

  const observer = useRef()
  const [renderLazyItem, toggleLazyItem] = useState(false)
  const lazyItem = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        toggleLazyItem(true)
      }
    });
    if (node)
      observer.current.observe(node);
  })

  const renderSublinks = (sublinks) => (
    <ul className={view["foot-sublinks"]}>
        {
          sublinks.map((item, idx) => (
            <li>
              <Link id='nav_link' key={idx} className={`${view["btn-text-sublink"]}`} to={item.linkValue}>
                <span>{item.linkName}</span>
              </Link>
            </li>))
        }
    </ul>
  )
  const renderLinks = () => (
    <div className={view["foot-cont"]}>
      {
        footer.links.map((item, idx) => {
          if (item.subLinks.length <= 0)
          return(
            <li className={`${view["col-1"]}`}>
              <Link id='nav_link' key={idx} className={`${view["btn-text"]}` }
                    to={item.linkValue}>
                <span>{item.linkName}</span>
              </Link>
            </li>
          )
        })
      }
    </div>
  )
  const renderLinkColumns = () => (
    <ul className={view["foot-link-header"]}>
      {
        footer.links.map((item, idx) => {
          if (item.subLinks.length > 0)
          return(
            <li className={`${view["solo-col"]}`}>
              <Link id='nav_link' key={idx} className={`${view["btn-text"]}` }
                    to={item.linkValue}>
                <span>{item.linkName}</span>
          </Link>
              {item.subLinks.length > 0 ? renderSublinks(item.subLinks) : ''}
            </li>
          )
        })
      }
    </ul>
  )
  const renderBottomLinks = () => (
    <div className={view["divider-box"]}>
      {
        footer.bottomLinks.map((item, idx) => {
          return(
            <Link id='nav_link' to={item.linkValue} className={view["subtitle"]}>{item.linkName}</Link>
          )
        })
      }
      <p className={` ${view["subtitle"]} ${view["last"]}`} style={{lineHeight: "1"}}>© 2009 - 2022, MXS Solutions - All Rights Reserved</p>
    </div>
  )

  const renderSocialColumn = () => {
    if(usableLink(footer.linkFB) || usableLink(footer.linkInsta) || usableLink(footer.linkTwit) || usableLink(footer.linkYelp) || usableLink(footer.linkYT) || usableLink(footer.linkLI)) 
    return(
         <div className={view["social-cont"]}>
          <h3>Connect With Us</h3>
          <div className={view['social-icon-list']}>
            {usableLink(footer.linkFB) ?
                <Link id='nav_link' to={footer.linkFB} alt="Our Facebook" className={`${view["social-icon"]} ${view["fb"]}`}></Link> : <span></span>}
            {usableLink(footer.linkInsta) ?
                <Link id='nav_link' to={footer.linkInsta} alt="Our Instagram" className={`${view["social-icon"]} ${view["insta"]}`}></Link> : <span></span>}
            {usableLink(footer.linkTwit) ?
                <Link id='nav_link' to={footer.linkTwit} alt="Our Twitter" className={`${view["social-icon"]} ${view["twitter"]}`}></Link> : <span></span>}
            {usableLink(footer.linkYelp) ?
                <Link id='nav_link' to={footer.linkYelp} alt="Our Yelp" className={`${view["social-icon"]} ${view["yelp"]}`}></Link> : <span></span>}
            {usableLink(footer.linkYT)?
                <Link id='nav_link' to={footer.linkYT} alt="Our Youtube" className={`${view["social-icon"]} ${view["youtube"]}`}></Link> : <span></span>}
            {usableLink(footer.linkLI) ?
                <Link id='nav_link' to={footer.linkLI} alt="Our LinkedIn" className={`${view["social-icon"]} ${view["linked"]}`}></Link> : <span></span>}
          </div>
        </div>
  )}

  const renderAppColumn = () => {
    if(usableLink(footer.linkIOS) || usableLink(footer.linkAndroid)) 
    return (
    <div className={view["app-cont"]}>
      {  <h3>Download Our App</h3>}
      { footer.linkIOS != '' ? <Link id='nav_link' to={footer.linkIOS} alt="Our IOS App" className={`${view["iosBtn"]} ${view["ios"]}`}></Link> : <span></span>}
      { footer.linkAndroid != '' ? <Link id='nav_link' to={footer.linkAndroid} alt="Our Android App" className={`${view["AndroidBtn"]} ${view["android"]}`}></Link> : <span></span>}
    </div>
  )}

  const loadFooterImage = () => {
    return typeof window !== 'undefined' && (
      <Link id='nav_link' to="/"><img className={view["foot-img"]} alt={"Footer Logo"} src={footer.logo}/></Link>
    )
  }

  const splitAddress = () => {
        let Address = dealerInfo.dealerAddress.split(',,');
        return (<> {Address.map((a,i,Address) => { return(<>{a} {((i+1 < Address.length) && <br/>)}</>)})}  </>)
    }

  const usableLink = (link) => {
    const notDefined = (link == undefined)
    const emptyString = !notDefined && link.replace(" ","") === ""
    return !(notDefined || emptyString)
  }
  let ourHours = dealerInfo.salesHours.map(entry => {
    return (
       <>{entry.time}<br />{entry.day}</>
    )
})
const currentYear = new Date().getFullYear();
  return (
    <>
    <footer id="footer" className={`${view["link-block"]} ${view["alt-footer"]}`}>
      <div className={view["first-third"]}>
          <Link id='nav_link' className="button button_type-icon header__logo icon_logo js-button nav-logo" to="/">
                <img className="nav-icon" src={props.dealerInfo.dealerLogo} alt={props.dealerInfo.dealerName} />
                <div className="MXSLogoBottom">
              MODERN/
              <div className="MXSLogoSlider">
                  <div className="logoSlider-text0">MARKETING</div>
                  <div className="logoSlider-text1">DIGITAL</div>
                  <div className="logoSlider-text2">WEBSITE</div>
                  <div className="logoSlider-text3">AUTOMOTIVE</div>
                  <div className="logoSlider-text4">DEVELOPMENT</div>
                  <div className="logoSlider-text5">SOFTWARE</div>
                  <div className="logoSlider-text6">eCOMMERCE</div>
                  <div className="logoSlider-text7">BRANDING</div>
                  <div className="logoSlider-text8">CREATIVE</div>
                  <div className="logoSlider-text9">MARKETING</div>
              </div>
              /SOLUTIONS
            </div>
          </Link>
        <div className={view["link-box"]}>
          {
            footer.bottomLinks.map((item, idx) => {
              if(!(idx === footer.bottomLinks.length - 1)) {
                return(
                  <Link id='nav_link' to={item.linkValue} className={view["subtitle"]}>{item.linkName}</Link>
                )
              }
            })
          }
        </div>
        
      </div>
      <div className={view["middle-third"]}>
        <div className={`${view["right_block"]} ${view["address"]}`}>
            <span className={view["map-icon"]}></span>
            <h3 className={`${view["mobile-loc"]} ${view["light-font"]}`}>Location</h3>
            <p id="ourAddress" className={view["light-font"]}>{splitAddress()}</p>
        </div>
        <div className={view["link-box"]}>
          {
            footer.bottomLinks.map((item, idx) => {
              if(idx === footer.bottomLinks.length - 1) {
                return(
                  <Link id='nav_link' to={item.linkValue} className={view["subtitle"]}>{item.linkName}</Link>
                )
              }
            })
          }
        </div>
      </div>
      <div className={view["last-third"]}>
        <div className={`${view["right_block"]} ${view["service"]}`}>
          <h3 className={`${view["light-font"]} ${view["phone-header"]}`}>Say Hello</h3>
          <p><a className={view["phone-field"]} href={"tel:" + dealerInfo.serviceNumber} data-department="sales">{dealerInfo.serviceNumber}</a></p>
          <h3 className={view["small-header"]}>Sales Inquiries:</h3>
          <p ><a className={view["small-contact"]} href="mailto:sales@teammxs.com">Sales@teammxs.com</a></p>
          <h3 className={view["small-header"]}>Technical Support:</h3>
          <p><a className={view["small-contact"]} href="mailto:support@teammxs.com">Support@teammxs.com</a></p>
        </div>
        <div className={`${view["right_block"]} ${view["hours"]}`}>
          <h3 className={view["light-font"]}>Hours</h3>
          <p className={`${view["hours-text"]} ${view["light-font"]}`}>{ourHours}</p>
        </div>
        <div className={view["link-box"]}>
          <p className={` ${view["subtitle"]}`} style={{lineHeight: "1"}}>© 2009 - {currentYear}, MXS Solutions - All Rights Reserved</p>
        </div>
      </div>
      <div className={view["mobile-link-box"]}>
          {
            footer.bottomLinks.map((item, idx) => {
                return(
                  <Link id='nav_link' to={item.linkValue} className={view["subtitle"]}>{item.linkName}</Link>
                )
            })
          }
      </div>
      <div className={view["mobile-copyright"]}>
      <p className={` ${view["subtitle"]}`} style={{lineHeight: "1"}}>© 2009 - {currentYear}, MXS Solutions - All Rights Reserved</p>
      </div>
    </footer>
{/*
    <footer id="footer" ref={lazyItem} className={view["footer"] + " " + (footerClassName === undefined ? "" : footerClassName)}>
      <button className={view["scrollTop"]} onClick={() => {window.scrollTo({top: 0, behavior: 'smooth'});}}>Top <span className={view["dash"]}></span></button>
      <div className={`${grid["row"]} ${view["foot-wrap"]}`}>
        <div className={`${view["dealer-info"]}`}>

            <div className={view["call-block"]}>
              <h3>Call us on</h3>
              <p>Sales <span>{dealerInfo.salesNumber}</span></p>
              <p>Service <span>{dealerInfo.serviceNumber}</span></p>
            </div>
            <div className={["stackdiv"]}>
            {(usableLink(footer.linkFB)
                || usableLink(footer.linkInsta)
                || usableLink(footer.linkTwit)
                || usableLink(footer.linkYelp)
                || usableLink(footer.linkYT)
                || usableLink(footer.linkLinked)) && renderSocialColumn()}
            {renderAppColumn()}
          </div>
        </div>
        <div className={`${view["link-block"]} ${grid["col-lg-12"]} ${view["foot-links"]} ${grid["col-md-8"]} ${grid["col-sm-8"]} `}>
          <Link id='nav_link' className="button button_type-icon header__logo icon_logo js-button nav-logo" to="/">
            <img className="nav-icon" src={props.dealerInfo.dealerLogo} alt={props.dealerInfo.dealerName} />
            <div className="MXSLogoBottom">
          MODERN/
          <div className="MXSLogoSlider">
              <div className="logoSlider-text0">MARKETING</div>
              <div className="logoSlider-text1">DIGITAL</div>
              <div className="logoSlider-text2">WEBSITE</div>
              <div className="logoSlider-text3">AUTOMOTIVE</div>
              <div className="logoSlider-text4">DEVELOPMENT</div>
              <div className="logoSlider-text5">SOFTWARE</div>
              <div className="logoSlider-text6">eCOMMERCE</div>
              <div className="logoSlider-text7">BRANDING</div>
              <div className="logoSlider-text8">CREATIVE</div>
              <div className="logoSlider-text9">MARKETING</div>
          </div>
          /SOLUTIONS
        </div>
          </Link>
          <div className="right_block">
            <h3>Location</h3>
            <p id="ourAddress">{dealerInfo.dealerAddress}</p>
          </div>
          <div className="right_block">
            <h3>Say Hello</h3>
            <p><a href={"tel:" + dealerInfo.serviceNumber}>{dealerInfo.serviceNumber}</a></p>
            <h3>Sales Inquiries:</h3>
            <p><a href="mailto:sales@mxssolutions.com">Sales@mxssolutions.com</a></p>
            <h3>Technical Support:</h3>
            <p><a href="mailto:support@mxssolutions.com">Support@mxssolutions.com</a></p>
          </div>
          <div className="right_block">
          <h3>Hours</h3>
          <p>{ourHours}</p>
          </div>
        </div>
        <div className={`${view["subtitle-box"]} ${grid["col-lg-12"]}`} style={{ textAlign: "center" }}>
          {renderBottomLinks()}

          <div className={view["dm-box"]} style={{display: "flex", flexDirection: "column"}}>
          <CookiePolicy disclaimer={footer.cookieDisclaimer} dealerInfo={dealerInfo} CookieLinkText="Manage Cookie Policy" />
          <Link id='nav_link' to={"/do-not-sell-my-personal-information"} className={`${view["ccpa"]}`}>Do Not Sell My Personal Information</Link>
          <Link id='nav_link' to={"https://dealermasters.com/"} className={`${view["last"]} ${view["DM-spot"]}`}>
            <p style={{ display: "inline-block" }}>Made with</p>
            <span className={view["heart"]} aria-label={"heart"} role={"img"}> &#10084; ️</span>
            <p style={{ display: "inline-block" }}>by Dealer Masters</p>
          </Link>
          </div>
        </div>
      </div>
    </footer> */}
    </>
  )
}

export default Footer
